import React, { Component, createRef } from 'react';
import { MdAddAPhoto } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { VscError } from "react-icons/vsc";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { toastError, toastSuccess, toastProgress } from '../Utils';

class UploadPhoto extends Component {
    state = {
        card: {}, // Card JSON
        admin: false, // Edit mode
        state: 0, // 0 idle 1 loading 2 loaded
        percentage: 0,
        src: ""
    }
    self = null;

    constructor(props){
        super(props);

        this.state.src = this.props.hasOwnProperty("src") ? this.props.src : "";
        this.state.state = this.state.src == "" ? 0 : 2;

        this.imgInput = createRef();
        this.self = this;
    }

    checkIt() {
        var self = window.uploadPhoto;
        var theFile = self.imgInput.current;
        // Check if the number of files
        // is not zero
        if (theFile.value.length) {
            // alert('Files Loaded');
        }

        // Alert the user if the number
        // of file is zero
        else {
            // alert('Cancel clicked');
        }
        document.body.onfocus = null;
    }

    initialize() {
        window.uploadPhoto = this.self;
        document.body.onfocus = this.checkIt;
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        this.state.src = this.props.hasOwnProperty("src") ? this.props.src : "";
        this.state.state = this.state.src == "" ? 0 : 2;
    }

    selectImage = () => {
        this.imgInput.current.click();
        // this.initialize()
    }

    fileSelected = () => {
        this.setState({state: 1});
        this.startUpload();
    }

    startUpload = () => {
        const axios = require('axios');
        var formData = new FormData();
        formData.append("img", this.imgInput.current.files[0]);
        formData.append("type", this.props.type);
        formData.append("position", this.props.position);
        formData.append("card", this.props.card);
        formData.append("code", this.props.code);

        var runUpload = () => {
          return new Promise((resolve, reject) => {
            axios.post('/api/upload', formData, {
              onUploadProgress: (progress) => {
                var perc = (progress.loaded/progress.total * 100).toFixed(0);
                this.setState({state: 1, percentage: perc});
              }
            }).then((response) => {
              if(response.status == 200){
                resolve(0)
                this.setState({state: 2, src: "/files/" + response.data.filename});
                this.props.callback(this.props.id, response.data.filename);
              }

              // toastSuccess("Immagine inviata!");
            }).catch((error) => {
              reject(1)
              this.setState({state: 3});
              toastError("Errore nell'invio dell'immagine. Dimensione massima: 10MB.", "upload-error");
            });
          })
        }
        toastProgress(runUpload)
    }

    render() {
        var innerButton = <MdAddAPhoto />;
        var style = {};
        if(this.state.state == 1){
            innerButton = <CircularProgressbar value={this.state.percentage} />;
        }
        if(this.state.state == 2){
            innerButton = <TiTick className="upload-confirm" />;
            style.backgroundImage = "url('/files/" + this.state.src + "')";
        }
        if(this.state.state == 3){
            innerButton = <VscError className="upload-error" />;
        }

        return <button className="button-upload" onClick={this.selectImage} style={style}>
                    <input type="file" name="img" id="imgupload" accept="image/*" style={{display:"none"}} ref={this.imgInput} onChange={this.fileSelected}/>
                    {
                        innerButton
                    }
                </button>
    }
}

export default UploadPhoto;
