import React, { Component } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import EditCover from './EditCover';

class Cover extends Component {
    state = {
        card: {}, // Card JSON
        admin: false // Edit mode
    }

    constructor(props){
        super(props);

        this.state.card = props.card;
        this.state.admin = props.admin;

        this.cover = React.createRef();
    }

    componentDidMount() {
        gsap.registerPlugin(ScrollTrigger);
            gsap.fromTo(this.cover.current, {
                opacity: 1,
                transform: "scale(1)"
            },{
                opacity: 0,
                transform: "scale(1.5)",
                ease: 'none',
                scrollTrigger: {
                    trigger: this.cover.current,
                    start: 'top top',
                    end: 'bottom top',
                    scrub: 1
            }
        });
    }

    componentDidUpdate(prevProps){
        // Cambio stato da visitatore ad admin e viceversa
        if(prevProps.admin != this.props.admin){
            this.setState({admin: this.props.admin});
        }
    }

    newCoverUploaded = (id, src) => {
        this.state.card.cover = src;
        this.setState({card: this.state.card});
        window.card.cover = src;
    }

    render() {
        var style = this.state.admin ? {backgroundImage: "url('/files/" + this.state.card.cover + "')"} : {backgroundImage: "url('/files/" + this.state.card.cover + "')"};
        return  <div className="cover-container">
                  <div className="cover-lateral"></div>
                  <div className="cover" style={style} ref={this.cover}>
                      {
                          this.state.admin ? <EditCover cover={this.cover} code={this.props.code} card={this.props.card.id} callback={this.newCoverUploaded} /> : <></>
                      }
                  </div>
                  <div className="cover-lateral"></div>
                </div>
    }
}

export default Cover;
