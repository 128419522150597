import React, { Component, useRef } from 'react';
import { Card } from '@fidize/ui.card';
import { Flex } from '@fidize/ui.flex';
import ReactCodeInput from 'react-code-input';
import menu from '../static/menu.png'

class Menu extends Component {
    state = {
        card: {}, // Card JSON
        admin: false, // Edit mode
        id: "", // ID card
        signature: "",
        insertedCode: false,
        flipped: false
    }
    isAndroid = false;

    constructor(props){
        super(props);

        this.menu = React.createRef();
        this.card = React.createRef();
        this.codeInput = React.createRef();

        var card = props.card;
        this.state.card = card;
        this.state.admin = props.admin;

        this.state.id = card.id;
        this.state.signature = card.hasOwnProperty("name") ? card.name[0][0].value : this.state.signature;
    }

    shouldComponentUpdate(newProps, newState){
        if(newProps.admin){
            newState.admin = true;
        }
        return true;
    }

    componentDidMount(){
        if(this.props.open){
            this.openMenu();
        }
        this.codeInput.current.textInput.map((input) => {
          input.placeholder = "-"
        })
        this.checkInsertedCode();
    }

    openMenu = () => {
        setTimeout(() => {
            this.card.current.flip();
            this.state.flipped = true;
        }, 500);
        this.menu.current.classList.add("opened");
        document.body.style = "overflow: hidden";
        setTimeout(() => {
            this.codeInput.current.textInput[0].focus();
        }, 2000);
    }

    closeMenu = () => {
        try{
            this.card.current.flip();
            this.menu.current.classList.remove("opened");
            document.body.style = "";
            document.body.focus();
        }catch(e){

        }
    }

    getCode = () => {
        var code = this.codeInput.current.textInput.reduce((tot, input) => {
          return tot + input.value
        }, "")
        return code;
    }

    checkInsertedCode = () => {
        var code = this.getCode();
        var regex = /^[a-zA-Z0-9]*$/g;
        if(!regex.test(code)){
          this.setState({insertedCode: false});
          return;
        }
        if(code.length == 6){
            this.setState({insertedCode: true});
            return;
        }
        this.setState({insertedCode: false});
    }

    // Login click
    login = async () => {
        this.setState({ insertedCode: false});
        var code = this.getCode();
        var logged = await this.props.login(code);
        if(logged){
            this.closeMenu();
            window.scroll(0,0)
            window.localStorage.code = code
        }
    }

    render() {
        var pin = {
          autoFocus: true,
          forceUppercase: true,
          value: window.localStorage.code,
          className: "code-div",
          pattern: "[A-Za-z0-9]{3}",
          inputStyleInvalid:{
            backgroundColor: "#550000"
          }
        }
        return <>
                {
                    !this.state.admin ?
                        <img src={menu} className="menu-icon" onClick={ this.openMenu } />
                    :
                        <></>

                }
                <div className="menu" ref={ this.menu }>
                    <div className="login-container">
                        <Flex type="justify_center" style={{marginTop: "30px", transform: "scale(0.8)"}}>
                            <Card faceColor="black" logo="./static/fidize.png" paintColor="gold" signature={this.state.signature} code={true} ref={this.card} contactless={true} flipped={this.state.flipped} />
                        </Flex>

                        <div className="menu-text bold">
                            <div class="menu-text-title bold" style={{marginBottom: "5px"}}>Modifica profilo</div>
                            Inserisci il codice che trovi nel retro della tua carta.
                        </div>

                        <div className="code-div">
                            <ReactCodeInput ref={this.codeInput} type='text' fields={6} onChange={this.checkInsertedCode} {...pin} />
                        </div>

                        <button className="button-primary" disabled={!this.state.insertedCode} onClick={this.login}>Accedi</button>
                        <button className="void-button blue" onClick={this.closeMenu} style={{marginTop: "15px", marginBottom: "15px"}}>Annulla</button>
                    </div>
                </div>
        </>
    }
}

export default Menu;
