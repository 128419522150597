import React, { Component } from 'react';

class Input extends Component {
    state = {
        value: ""
    }

    constructor(props){
        super(props);

        this.state.value = props.value;
    }

    clear = () => {
        this.setState({value: ""})
    }

    getValue(){
        return this.state.value;
    }

    handleChange = (event) => {
        var newvalue = event.target.value;
        this.setState({value: newvalue});
        if(this.props.callback){
            this.props.callback(this.props.id, newvalue);
        }
    }

    handleFocus = (event) => {
      if(this.state.value == this.props.default & this.props.default.length > 8){
        this.setState({value: ""});
        this.props.callback(this.props.id, "");
      }
    }

    render() {
        console.log(this.state.value , this.props.default, this.state.value == this.props.default);
        var style = this.props.hasOwnProperty("style") ? this.props.style : {};
        return  <div className="input-div" style={{...style}}>
                  <input value={this.state.value} className={(this.state.value == this.props.default ? "input-field placeholder" : "input-field")} onChange={this.handleChange} onFocus={this.handleFocus}></input>
                <div className="input-line"></div>
        </div>
    }
}

export default Input;
