import React, { Component } from 'react';
import Cover from './Cover';
import Footer from './Footer';
import Gallery from './Gallery';
import Hr from './Hr';
import Menu from './Menu';
import Picture from './Picture';
import Save from './Save';
import Slogan from './Slogan';
import Social from './Social';
import TextItem from './TextItem';
import Map from './Map';
import Vcard from './Vcard';
import { IoIosShareAlt } from 'react-icons/io';
import { toastError, toastSuccess } from '../Utils';

class Profile extends Component {
    state = {
        card: null, // Card JSON
        comps: [], // Components
        code: "",
        id: 0,
        admin: false, // Edit mode
        toSave: false, // Feedback per suggerire di salvare modifiche
        openMenu: false
    }
    overlay = null;

    constructor(props){
        super(props);

        this.state.id = this.props.match.params.card;
    }

    getData = async (card) => {
        const response = await fetch('/api/card/' + card, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            }
        });
        var j = await response.json();
        if(Object.keys(j).length == 0){
            j.id = this.state.id;
            window.card = j;
            return this.setState({ card: j, openMenu: true});
        }
        j.id = this.state.id;
        window.card = j;
        return this.setState({ card: j, openMenu: false});
    };

    alertSave = (active) => {
        this.setState({toSave: active});
    }

    // Login request
    login = async (code) => {

        return new Promise(async (resolve) => {
            const response = await fetch('/api/login', {
                method: 'POST',
                body: JSON.stringify({card: this.state.id, code: code}),
                headers: {
                'Content-Type': 'application/json',
                }
            });
            const j = await response.json();

            if(j.login){
                this.setState({ admin: true , code: code});
                resolve(true);

            }else{
                this.setState({ admin: false , code: ""});
                toastError("Codice non valido! Puoi trovarlo nel retro della carta.", "code-error");
                resolve(false);
            }
        })
    }

    copyLink = () => {
      var copyText = document.getElementById("url");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value);
      document.body.focus();

      toastSuccess("Link copiato", "copy-link");
    }

    componentDidMount(){
        this.getData(this.props.match.params.card)
    }

    render() {
        if(this.state.card == null){
            return <></>;
        }
        return <>
                    {
                        !this.state.admin?
                            <Menu card={this.state.card} admin={this.state.admin} login={this.login} open={this.state.openMenu}/>
                        :
                            <Menu card={this.state.card} admin={this.state.admin} login={this.login} open={this.state.openMenu}/>
                    }
                    <div className="all">
                        <div className="edit-overlay" id="edit-overlay"></div>
                        <Cover card={this.state.card} admin={this.state.admin} code={this.state.code} />

                        <div class="content">
                            <div class="profile" id="profile">
                                <Picture card={this.state.card} admin={this.state.admin} code={this.state.code} />
                                <Slogan card={this.state.card} admin={this.state.admin} />
                                <TextItem card={this.state.card} itemName="name" title="Nome e cognome" multi={false} admin={this.state.admin} default={[{title: "Nome e cognome", value: "Il tuo nome"}, {title: "Titolo", value: "es. Dott., Avv."}]} />
                                <TextItem card={this.state.card} itemName="companies" title="Esperienza" multi={true} admin={this.state.admin} default={[{title: "Azienda", value: "La tua azienda"}, {title: "Qualifica", value: "La tua qualifica"}]} />
                                <TextItem card={this.state.card} itemName="emails" title="Email" multi={true} admin={this.state.admin} default={[{title: "Email", value: "latuaemail@email.com"}]} link={true} />
                                <TextItem card={this.state.card} itemName="mobiles" title="Cellulare" multi={true} admin={this.state.admin} default={[{title: "Cellulare", value: "+39"}]} link={true} />
                                <TextItem card={this.state.card} itemName="phones" title="Telefono" multi={true} admin={this.state.admin} default={[{title: "Telefono", value: "+39"}]} link={true} />
                                <Map card={this.state.card} itemName="addresses" title="Indirizzo" multi={false} admin={this.state.admin} default={[{title: "Indirizzo", value: "Il tuo indirizzo"}]} />
                                <TextItem card={this.state.card} itemName="websites" title="Website" multi={true} admin={this.state.admin} default={[{title: "Website", value: "https://"}]} link={true} />
                                <TextItem card={this.state.card} itemName="note" title="Note" multi={true} admin={this.state.admin} default={[{title: "Note", value: "Le tue note"}]} />
                                <TextItem card={this.state.card} itemName="billing" title="Fatturazione" multi={false} admin={this.state.admin} default={[{title: "Ragione sociale", value: "Azienda  "},{title: "Indirizzo", value: "via Roma 1"},{title: "CAP", value: "01234    "},{title: "Città", value: "Roma     "},{title: "Provincia", value: "Roma     "},{title: "P.IVA", value: "IT0000000000"},{title: "SDI", value: "00000000   "},{title: "IBAN", value: "IT0000000000"}, {title: "IBAN", value: "IT0000000000"}, {title: "PEC", value: "email@pec.it"}]} />
                                <Social card={this.state.card} admin={this.state.admin} />
                                {
                                    this.state.card != null && Object.keys(this.state.card).length > 1 ?
                                        <>
                                          <Vcard card={this.state.card} admin={this.state.admin} />
                                          <br></br>
                                          <input type="text" id="url" value={"https://app.fidize.com/" + this.state.id} style={{display: "none"}}/>
                                          {
                                            this.state.admin ?
                                              <></> :
                                              <span className="link-copy blue" onClick={this.copyLink}>Condividi profilo <span className="link-copy-icon"><IoIosShareAlt /></span></span>
                                          }
                                        </> :
                                        <></>
                                }
                                <Gallery card={this.state.card} admin={this.state.admin} code={this.state.code} />

                                {
                                    this.state.admin ?  <>
                                                            <div style={{height: "70px"}}></div>
                                                            {

                                                            }
                                                            <Save attention={this.state.toSave}  code={this.state.code} />
                                                        </> : <></>
                                }
                                {/* <Hr /> */}
                                {/* <Footer /> */}
                            </div>
                        </div>
                    </div>
        </>
    }
}

export default Profile;
