import React, { Component, createRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BsFillTrashFill } from 'react-icons/bs';
import addPhoto from "../static/photo.jpg";
import UploadPhoto from './UploadPhoto';
import { removeFile } from '../Utils';


class Gallery extends Component {
    state = {
        card: {}, // Card JSON
        admin: false, // Edit mode
        gallery: [],
        galleryTitle: ""
    }

    constructor(props){
        super(props);

        var card = props.card;
        this.state.card = card;
        this.state.admin = props.admin;
        this.state.gallery = card.hasOwnProperty("gallery") ? card.gallery : this.state.gallery;
        this.state.gallery = this.filterGallery(this.state.gallery);
        this.state.galleryTitle = card.hasOwnProperty("galleryTitle") ? card.galleryTitle : this.state.galleryTitle;
        this.upload = createRef();
    }

    componentDidUpdate(prevProps){
        // Cambio stato da visitatore ad admin e viceversa
        if(prevProps.admin != this.props.admin){
            this.setState({admin: this.props.admin});
        }
    }

    setGallery = (newgallery) => {
        this.setState({gallery: newgallery.photos, galleryTitle: newgallery.title}, () => {
            this.gallery.current.swiper.update();
        });
    }

    filterGallery(gallery){
        var newGallery = gallery.reduce((tot, p) => {
            if(p != "")
                tot.push(p)
            return tot;
        }, [])
        return newGallery;
    }

    selectImage = () => {
        this.upload.current.selectImage()
    }

    loadedImg = (data, src) => {
        this.state.gallery.push(src);
        this.setState({gallery: this.state.gallery});
        window.card.gallery = this.state.gallery;
    }

    removeImage = (id) => {
        removeFile("gallery", id+1, this.state.card.id, this.props.code, (response) => {
            if(response == 200){
                this.state.gallery.splice(id, 1);
                this.setState({gallery: this.state.gallery});
                window.card.gallery = this.state.gallery;
            }
        })
    }

    render() {
        return <div className="gallery">
            <div className="item gallery-album">
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1.7}
                    centerSlides={true}
                    centerInsufficientSlides={true}
                    ref={this.gallery}
                    >
                        {
                            this.state.gallery.map((p, id) => {
                                return p != "" ? <SwiperSlide>
                                            <div class="swiper-content">
                                                <div class="gallery-img" style={{backgroundImage: "url('/files/" + p + "')"}}>
                                                    {
                                                        this.state.admin ?
                                                        <button className="button-cancel edit-delete gallery-delete" onClick={() => {this.removeImage(id)}} >
                                                            <div className='centered' style={{width: "100%"}}>
                                                                <BsFillTrashFill />
                                                            </div>
                                                        </button> :
                                                        <></>
                                                    }
                                                    <img class="gallery-nophoto" src="/static/nophoto.png" />
                                                </div>
                                            </div>
                                        </SwiperSlide> : <></>;
                            })
                        }{
                            this.state.gallery.length < 5 & this.props.admin ? <SwiperSlide style={{height:"100%"}}>
                                                                <div class="gallery-img" style={{backgroundImage: "url('/static/addphoto.jpg')"}} onClick={this.selectImage}>
                                                                    <img class="gallery-nophoto" src="/static/nophoto.png" />
                                                                </div>
                                                                <div style={{display: 'none'}}>
                                                                    <UploadPhoto type="gallery" position={this.state.gallery.length + 1} callback={this.loadedImg} card={this.props.card.id} code={this.props.code} ref={this.upload} />
                                                                </div>
                                                            </SwiperSlide> : <></>
                        }
                </Swiper>

                {/* <div class="gallery-title">{ this.state.galleryTitle }</div> */}
            </div>
        </div>
    }
}

export default Gallery;
