import React, { Component } from 'react';
import EditSlogan from './EditSlogan';

class Slogan extends Component {
    state = {
        card: {}, // Card JSON
        admin: false, // Edit mode
        slogan: ""
    }

    constructor(props){
        super(props);

        var card = props.card;
        this.state.card = card;
        this.state.admin = props.admin;

        this.state.slogan = card.hasOwnProperty("slogan") ? card.slogan : this.state.slogan;

        this.slogan = [];
    }

    componentDidUpdate(prevProps){
        // Cambio stato da visitatore ad admin e viceversa
        if(prevProps.admin != this.props.admin){
            this.setState({admin: this.props.admin});
        }
    }

    getEditable = (id) => {
        return this.editables;
    }

    setText = (text) => {
        this.setState({slogan: text})
    }

    render() {
        this.editables = [];

        return <div>
                    { this.state.admin ? <EditSlogan text={this.state.slogan} setText={this.setText} /> : <></>}
                    <div className="centered">
                        <div className={"slogan " + (this.state.admin ? " input-edit-moded" : "")} ref={(e) => {if(e != null){this.editables.push(e)}}}>
                            {
                                this.state.admin && this.state.slogan.length == 0 ?
                                "Biografia" :
                                <span>{ this.state.slogan.split("\n").map((txt) => {return <>{ txt }<br></br></>}) }</span>
                            }
                        </div>
                    </div>
                    { this.state.slognan != "" ? <div className="slogan-margin"></div> : <></>}
            </div>
    }
}

export default Slogan;
