import React, { Component } from 'react';

import 'swiper/swiper.min.css'
import './App.css';
import { Route, Switch } from 'react-router-dom';
import Profile from './components/Profile';
import NotFound from './components/NotFound';

class App extends Component {
  state = {
  };

  constructor(props){
    super(props);
  }

  render() {
    return (
      <>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"></meta>
        <div className="app">
            <Switch>
              <Route path='/:card' render={(props) => {
                return <Profile card={this.state.card} admin={false} {...props} />
              }} />
              <Route path='*' exact={true} component={NotFound} />
            </Switch>
        </div>
      </>
    );
  }
}

export default App;