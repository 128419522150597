import React, { Component } from 'react';
import VCard from 'vcard-creator'
import EditVcard from './EditVcard';

class Vcard extends Component {
    state = {
        card: {}, // Card JSON
        admin: false, // Edit mode
        vcard: [] //JSON del vcard...va elaborato
    }

    constructor(props){
        super(props);

        var card = props.card;
        this.state.card = card;
        this.state.admin = props.admin;

        this.state.vcard = card.hasOwnProperty("vcard") ? card.vcard : [[]];

    }

    componentDidUpdate(prevProps){
        // Cambio stato da visitatore ad admin e viceversa
        if(prevProps.admin != this.props.admin){
            this.setState({admin: this.props.admin});
        }
    }

    setVcard = (newvcard) => {
        this.setState({vcard: newvcard});
    }

    getValue = (id) => {
        var vcard = this.state.vcard;
        for(var x=0; x<vcard[0].length; x++){
            if(vcard[0][x].id == id){
                return vcard[0][x].value;
            }
        }
        return "";
    }

    getName(){
        var name = "";
        try{
            name = window.card.name[0][0].value;
        }catch(e){

        }
        return name;
    }

    getCompany(){
        var comp = "";
        try{
            comp = window.card.companies[0][0].value;
        }catch(e){

        }
        return comp;
    }

    getJob(){
        var job = "";
        try{
            job = window.card.companies[0][1].value;
        }catch(e){

        }
        return job;
    }

    getEmail(){
        var email = "";
        try{
            email = window.card.emails[0][0].value;
        }catch(e){

        }
        return email;
    }

    getPhones(){
        var phones = []
        try{
            if(window.card.hasOwnProperty("phones")){
                phones = window.card.phones[0].reduce((tot, p) => {
                    tot.push(p.value);
                    return tot;
                }, [])
            }
        }catch(e){}
        try{
            if(window.card.hasOwnProperty("mobiles")){
                phones = window.card.mobiles[0].reduce((tot, p) => {
                    tot.push(p.value);
                    return tot;
                }, phones)
            }
        }catch(e){}
        return phones
    }

    getUrl(){
        var url = ""
        try{
            url = window.card.websites[0][0].value;
        }catch(e){

        }
        return url;
    }

    downloadVcf = () => {
        // https://www.npmjs.com/package/vcard-creator.
        var vcard = new VCard();
        vcard.addName("", this.getName() + "", "", "", "");
        vcard.addCompany(this.getCompany());
        vcard.addJobtitle(this.getJob());
        vcard.addEmail(this.getEmail());
        var phones = this.getPhones();
        for(var x=0; x<phones.length; x++){
            vcard.addPhoneNumber(phones[x], 'WORK');
        }
        vcard.addURL(this.getUrl(), 'WORK');


        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/vcard;charset=utf-8,' + encodeURIComponent(vcard.toString()));
        element.setAttribute('download', "vcf.vcf");

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    render() {
        return <div class="centered" style={{position: "relative"}}>
            {
                this.state.admin ? <></> :
                    <div class="item" style={{marginTop: "100px"}}>
                        <button class="button-primary" onClick={this.downloadVcf} style={{marginTop: "0px"}}>Salva contatto</button>
                    </div>
            }
        </div>
    }
}

export default Vcard;