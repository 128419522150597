import React, { Component } from 'react';
import { BsArchiveFill, BsFillPersonFill } from 'react-icons/bs';
import { MdWork, MdOutlineKeyboardArrowUp, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { ImMobile, ImLocation } from 'react-icons/im';
import { BiWorld, BiReceipt } from 'react-icons/bi';
import { GoNote } from 'react-icons/go';
import { FaPhoneAlt } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { Textfit } from 'react-textfit';
import Addnew from './Addnew';
import Edit from './Edit';
import Expand from 'react-expand-animated';

class TextItem extends Component {
    state = {
        admin: false, // Edit mode
        items: [],
        expanded: false,
        multi: false
    }
    afterDelete = false;

    constructor(props){
        super(props);

        var card = props.card;
        this.state.card = card;
        this.state.admin = props.admin;

        this.editables = [];

        this.state.items = card.hasOwnProperty(this.props.itemName) ? card[this.props.itemName] : this.state.items;
    }

    componentDidUpdate(prevProps){
        // Cambio stato da visitatore ad admin e viceversa
        if(prevProps.admin != this.props.admin){
            this.setState({admin: this.props.admin});
        }
        if(this.afterDelete){
          this.afterDelete = false;
          this.setState({items: this.state.items});
        }
    }

    checkWidth = () => {

    }

    componentDidMount(){

    }

    getIcon = () => {
        switch(this.props.itemName){
            case "name":
                return <>👤</>
            case "companies":
                return <>💼</>
            case "emails":
                return <>📧</>
            case "mobiles":
                return <>📱</>
            case "phones":
                return <>📞</>
            case "addresses":
                return <>📍</>
            case "websites":
                return <>🌍</>
            case "billing":
                return <>🧾</>
            default:
                return <>📝</>
        }
    }

    isSet = (id) => {
        if(this.state.admin){
            return false;
        }
        return this.props.hasOwnProperty(id);
    }

    getLink = (txt) => {
        if(this.props.itemName == "emails"){
            return "mailto:" + txt;
        }
        if(this.props.itemName == "websites"){
            if(!txt.indexOf("http")){
                return  txt;
            }
            return "https://" + txt;
        }
        if(this.props.itemName == "mobiles" || this.props.itemName == "phones"){
            return "tel:" + txt;
        }
    }

    getEditable = (id) => {
        return this.editables[id];
    }

    addNew = () => {
        var newstate = this.state.items;
        newstate.push(JSON.parse(JSON.stringify(this.props.default)));
        this.setState({ items: newstate });
        window.card[this.props.itemName] = this.state.items;
    }

    editCallback = (id, newvalue) => {
        this.state.items[id] = newvalue;
        window.card[this.props.itemName] = this.state.items;
        setTimeout(() => {
            this.setState({items: this.state.items});
        }, 1000)
    }

    delete = (id) => {
        this.state.items.splice(id, 1);
        this.afterDelete = true;
        this.setState({items: this.state.items})
        window.card[this.props.itemName] = this.state.items;
    }

    triggeExpand = () => {
      this.setState({expanded: !this.state.expanded})
    }

    render() {
        this.editables = [];

        if(this.state.items.length == 0 & !this.state.admin){
            return <></>
        }
        var kid = Date.now();

        return <div class="centered">
            <table class="item">
                <tbody>
                    <tr>
                        <td class="item-icon">{ this.getIcon() }</td>

                        <td class="item-name-td">
                            <span class="item-name">{this.props.title}</span>
                        </td>

                        <td class="item-content">
                            {
                                this.state.items.map((e, id) => {
                                    var fields = e;
                                    kid++;
                                    var nLines = e.reduce((tot, v) => {
                                      if(v.value.trim().length > 0){
                                        tot = tot + 1
                                      }
                                      return tot;
                                    }, 0)

                                    var fatturazione = <></>
                                    if(e.length > 2){
                                      fatturazione = <>{
                                          e.map((v, i) => {
                                            if(i != 9 & v.value.trim().length > 0)
                                              return  <>
                                                        <span className="item-billing-label">{v.title}</span><br></br>
                                                        <span>{v.value.trim()}</span><br></br>
                                                      </>
                                            else if(v.value.trim().length > 0){
                                              if(this.state.admin){
                                                return <><span>{v.value.trim()}</span><br></br></>
                                              }
                                              return  <>
                                                        <span className="item-billing-label">{v.title}</span><br></br>
                                                        <a href={"mailto:" + v.value.trim()}><Textfit mode="single" max={18}>{ v.value.trim() }</Textfit></a>
                                                      </>
                                            }
                                          })
                                        }
                                      </>

                                    }

                                    // Aggiungo i default se non sono stati compilati
                                    if(this.state.admin & e.length != this.props.default.length){
                                      var tmp = JSON.parse(JSON.stringify(e))
                                      e = JSON.parse(JSON.stringify(this.props.default))
                                      e.map((e_default => {
                                        for(var eid = 0; eid<tmp.length; eid++){
                                          if(tmp[eid].title == e_default.title){
                                            e_default.value = tmp[eid].value
                                          }
                                        }
                                      }))
                                    }
                                    fields = e

                                    return <div class="item-element" key={e.length > 2 ? "billing" : kid}>
                                            <div className="item-label-element-container">
                                                <div className={"item-label-element"} ref={(ele) => {if(ele != null)this.editables.push(ele)}}>
                                                    <div className={this.state.admin ? "item-editable" : ""}>
                                                        {
                                                            e.length < 3 ?
                                                              e[0].value.trim().length == 0 ?
                                                                <></> :
                                                                this.isSet("link") ?
                                                                  <a href={ this.getLink(e[0].value) }><Textfit mode="single" max={18}>{ e[0].value.trim() }</Textfit></a> :
                                                                  e.length == 2 && e[1].title == "Titolo" ?
                                                                    <span>{ this.props.default[1].value == e[1].value ? "" : e[1].value.trim() } { this.props.default[0].value == e[0].value ? "" : e[0].value.trim() }</span> :
                                                                    <span>{ this.props.default[0].value == e[0].value ? "" : e[0].value.trim() }</span>
                                                            :
                                                            //Fatturazione
                                                            (!this.state.admin & nLines > 3) ?
                                                              <>
                                                                <Expand open={this.state.expanded} styles={{close: {height: "87px", opacity: 1}}} duration={1000} transitions={["height", "opacity"]}>
                                                                  {
                                                                    fatturazione
                                                                  }
                                                                </Expand>
                                                                {
                                                                  this.state.expanded ?
                                                                  <span onClick={this.triggeExpand} className="expand-container blue">Chiudi <span className="expand-icon"><MdOutlineKeyboardArrowUp /></span></span> :
                                                                  <span onClick={this.triggeExpand} className="expand-container blue">Espandi <span className="expand-icon"><MdOutlineKeyboardArrowDown /></span></span>
                                                                }
                                                              </> :
                                                              fatturazione
                                                        }
                                                    </div>
                                                    {
                                                        e.length == 2 && e[1].title != "Titolo" ? <div className="item-label item-editable">{ e[1].value }</div> : <></>
                                                    }
                                                </div>
                                                {
                                                    this.state.admin ? <Edit getEditable={this.getEditable} id={id} fields={fields} default={this.props.default} callback={this.editCallback} delete={this.delete} /> : <></>
                                                }
                                            </div>
                                        </div>;
                                })
                            }

                            {
                                (this.state.admin & this.props.multi) ? <Addnew onClick={this.addNew} /> : <></>
                            }

                            {
                                (this.state.admin & !this.props.multi & this.state.items.length == 0) ? <Addnew onClick={this.addNew} /> : <></>
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    }
}

export default TextItem;
