import React, { Component, createRef } from 'react';
import Input from './Input';

class EditVcard extends Component {
    state = {
    }

    constructor(props){
        super(props);

        this.fields = this.getVcard();

        this.form = createRef();
    }

    trigEdit = () => {
        var overlay = document.getElementById("edit-overlay");
        if(overlay.classList.contains("editing")){
            // Chiudo il form
            this.form.current.classList.remove("editing");
            this.props.callback(this.fields);
            setTimeout(() => {
                overlay.classList.remove("editing");
            }, 800);
        }else{
            // Apro il form
            overlay.classList.add("editing");
            this.form.current.classList.add("editing");
        }
    }

    callback = (id, value) => {
        var vcardItems = this.fields;
        this.fields[0][this.isSet(vcardItems[id].id)] = value;
    }

    isSet = (vcard = this.fields, item) => {
        var items = vcard[0];
        for(var x=0; x<items.length; x++){
            if(items[x].id == item){
                return x;
            }
        }
        return -1;
    }

    getVcard = () => {
        var vcardItems = [];

        vcardItems.push({title:"First name", id: "fname", value: ""});
        if(this.isSet(this.props.fields, "fname") != -1){
            vcardItems[vcardItems.length - 1].value = this.props.fields[0][this.isSet(this.props.fields, "fname")].value + "";
        }

        vcardItems.push({title:"Last name", id: "lname", value: ""});
        if(this.isSet(this.props.fields, "lname") != -1){
            vcardItems[vcardItems.length - 1].value = this.props.fields[0][this.isSet(this.props.fields, "lname")].value + "";
        }

        vcardItems.push({title:"Work phone", id: "wphone", value: ""});
        if(this.isSet(this.props.fields, "wphone") != -1){
            vcardItems[vcardItems.length - 1].value = this.props.fields[0][this.isSet(this.props.fields, "wphone")].value + "";
        }


        return [vcardItems];
    }

    render() {
        var vcard = this.fields;

        return  <>  <button className="button-light" style={{position: "absolute", bottom: "-30px", right: "30px"}} onClick={this.trigEdit}>Edit contact</button>

                    <div className="edit-form edit-slogan" ref={this.form}>
                        <div className="edit-form-div">
                            {
                                vcard[0].map((v, id) => {
                                    return <div className="edit-form-field">
                                                <div className="item-label" style={{color: "#202020"}}>{ v.title }</div>
                                                <Input value={v.value} id={id} callback={this.callback} />
                                            </div>
                                })
                            }
                            <div className="edit-div-action">
                                <button className="edit-confirm" onClick={() => {this.trigEdit()}}></button>
                            </div>
                        </div>
                    </div>
                </>
    }
}

export default EditVcard;