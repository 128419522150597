import { Flex } from '@fidize/ui.flex/dist/flex';
import React, { Component, createRef } from 'react';
import { BsFillTrashFill, BsCheckCircleFill } from 'react-icons/bs';
import Input from './Input';

class EditSocial extends Component {
    state = {
    }

    inputs = []

    constructor(props){
        super(props);

        this.fields = { ...this.props.fields };

        this.form = createRef();
    }

    trigEdit = () => {
        var overlay = document.getElementById("edit-overlay");
        if(overlay.classList.contains("editing")){
            // Chiudo il form
            this.form.current.classList.remove("editing");
            this.props.callback(this.fields);
            setTimeout(() => {
                overlay.classList.remove("editing");
            }, 800);
        }else{
            // Apro il form
            overlay.classList.add("editing");
            this.form.current.classList.add("editing");
        }
    }

    callback = (id, value) => {
        var socials = this.getSocials();
        this.fields[socials[id].id] = value
    }

    isSet = (social) => {
        return this.props.fields.hasOwnProperty(social);
    }

    getPrefix = (title) => {
        switch (title.toLowerCase()) {
            case "facebook":
                return "https://facebook.com/"
                break;

            case "instagram":
                return "https://www.instagram.com/"
                break;

            case "tiktok":
                return "https://www.tiktok.com/@"
                break;

            case "twitter":
                return "https://mobile.twitter.com/"
                break;

            case "tumblr":
                return "https://www.tumblr.com/"
                break;

            case "linkedin":
                return "https://www.linkedin.com/in/"
                break;

            case "whatsapp":
                return "https://wa.me/39"
                break;

            case "telegram":
                return "https://t.me/"
                break;

            case "skype":
                return "https://join.skype.com/invite/"
                break;

            case "youtube":
                return "https://www.youtube.com/"
                break;

            case "twitch":
                return "https://www.twitch.tv/"
                break;

            case "snapchat":
                return "https://www.snapchat.com/add/"
                break;

            default:
                break;
        }
        return "";
    }

    getSocials = () => {
        var socials = [];

        socials.push({title:"Facebook", icon: "facebook", id: "facebook", url: ""});
        if(this.isSet("facebook")){
            socials[socials.length - 1].url = this.fields["facebook"] + "";
        }

        socials.push({title:"Instagram", icon: "instagram", id: "instagram", url: ""});
        if(this.isSet("instagram")){
            socials[socials.length - 1].url = this.fields["instagram"] + "";
        }

        socials.push({title:"TikTok", icon: "tiktok", id: "tiktok", url: ""});
        if(this.isSet("tiktok")){
            socials[socials.length - 1].url = this.fields["tiktok"] + "";
        }

        socials.push({title:"Twitter", icon: "twitter", id: "twitter", url: ""});
        if(this.isSet("twitter")){
            socials[socials.length - 1].url = this.fields["twitter"] + "";
        }

        socials.push({title:"Linkedin", icon: "linkedin", id: "linkedin", url: ""});
        if(this.isSet("linkedin")){
            socials[socials.length - 1].url = this.fields["facebook"] + "";
        }

        socials.push({title:"Whatsapp", icon: "whatsapp", id: "whatsapp", url: ""});
        if(this.isSet("whatsapp")){
            socials[socials.length - 1].url = this.fields["whatsapp"] + "";
        }

        socials.push({title:"Telegram", icon: "telegram", id: "telegram", url: ""});
        if(this.isSet("telegram")){
            socials[socials.length - 1].url = this.fields["telegram"] + "";
        }

        socials.push({title:"Skype", icon: "skype", id: "skype", url: ""});
        if(this.isSet("skype")){
            socials[socials.length - 1].url = this.fields["skype"] + "";
        }

        socials.push({title:"YouTube", icon: "youtube", id: "youtube", url: ""});
        if(this.isSet("youtube")){
            socials[socials.length - 1].url = this.fields["youtube"] + "";
        }

        socials.push({title:"Twitch", icon: "twitch", id: "twitch", url: ""});
        if(this.isSet("twitch")){
            socials[socials.length - 1].url = this.fields["twitch"] + "";
        }

        socials.push({title:"Snapchat", icon: "snapchat", id: "snapchat", url: ""});
        if(this.isSet("snapchat")){
            socials[socials.length - 1].url = this.fields["snapchat"] + "";
        }

        return socials;
    }

    delete = (id) => {
        this.fields[this.getSocials()[id].id] = "";
        this.inputs[id].clear();
        this.setState({})
    }

    render() {
        var socials = this.getSocials();

        return  <>
                    <div className="edit-form edit-slogan edit-social" ref={this.form}>
                        <div className="edit-form-div">
                            {
                                socials.map((s, id) => {
                                    return <div className="edit-form-field">
                                                <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                                                    <span className="item-label" style={{color: "#202020"}}>{ s.title }</span>
                                                    <button className="button-cancel void-button edit-delete delete-social" onClick={() => {this.delete(id)}} style={{fontSize: "16px !important"}}><BsFillTrashFill /></button>
                                                </div>

                                                <Flex style={{width: "100%"}}>
                                                    <Flex type="column justify_center">
                                                        <span className="socialPrefix">{this.getPrefix(s.title)}</span>
                                                    </Flex>
                                                    <Input value={s.url} id={id} callback={this.callback} ref={(el) => {if(this.inputs.length <= id){this.inputs.push(el)}}} style={{width: "100%"}}/>
                                                </Flex>
                                            </div>
                                })
                            }
                            <div className="edit-div-action">
                                <button className="button-primary" onClick={() => {this.trigEdit()}} style={{fontSize: "16px"}}>Applica</button>
                            </div>
                        </div>
                    </div>
                    <div style={{position: "relative", textAlign: "right", height: "25px"}}>
                        <button className="button-text edit-button" style={{bottom: "0px", right: "0px"}} onClick={this.trigEdit}>Modifica social</button>
                    </div>
                </>
    }
}

export default EditSocial;
