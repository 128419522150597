import React, { Component } from 'react';
import { FaSave } from 'react-icons/fa';
import { saveProfile } from '../Utils';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

class Save extends Component {
    state = {
        admin: false, // Edit mode
        attention: false,
        loading: false
    }

    constructor(props){
        super(props);
    }

    componentDidUpdate(prevProps){
        if(prevProps.attention != this.props.attention)
            this.setState({attention: this.props.attention});
    }

    save = () => {
        this.setState({loading: true});
        var self = this;
        saveProfile(window.card.id, this.props.code, window.card, (status) => {
            self.setState({loading: false});
        });
    }

    render() {
        return <button className={"button-primary save-button" + (this.state.attention ? " toSave" : "")} onClick={this.save} disabled={this.state.loading}>
                    {
                        this.state.loading ? <Loader
                                                type="Circles"
                                                color="#00BFFF"
                                                height={30}
                                                width={300}
                                            /> : <></>
                    }
                    Salva modifiche
                </button>
    }
}

export default Save;