import React, { Component, createRef } from 'react';
import { MdAddAPhoto, MdRemoveCircle } from "react-icons/md";
import UploadPhoto from './UploadPhoto';

class EditPicture extends Component {
    state = {
        fields: []
    }

    constructor(props){
        super(props);

        this.state.fields = this.props.fields;

        this.form = createRef();
        this.upload = createRef();
    }

    trigEdit = () => {
        this.upload.current.selectImage();
        return;

        var overlay = document.getElementById("edit-overlay");
        if(overlay.classList.contains("editing")){
            // Chiudo il form
            this.form.current.classList.remove("editing");

            setTimeout(() => {
                overlay.classList.remove("editing");
            }, 200);

            setTimeout(() => {
                this.props.picture.current.classList.remove("picture-focus");
            }, 1300);
        }else{
            // Apro il form
            overlay.classList.add("editing");
            this.form.current.classList.add("editing");

            this.props.picture.current.classList.add("picture-focus");

            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });

        }
    }

    callback = (res, src) => {
        this.props.callback(res, src);
        this.trigEdit();
    }

    render() {
        return  <>  <button className="button-light" style={{padding: "7px 200px", fontSize: "25px", margin: "0px"}} onClick={this.trigEdit}><MdAddAPhoto /></button>

                    <div className="edit-form edit-form-full" style={{display: "none"}} ref={this.form}>
                        <div className="edit-form-div">
                            <div className="item-label" style={{color: "#202020", textAlign: "center", paddingLeft: "0px"}}>Change your picture</div>
                            <div className="edit-select-image-div">
                                <UploadPhoto code={this.props.code} type="picture" position="1" card={this.props.card} callback={this.callback} ref={this.upload} />
                            </div>
                            <div className="edit-div-action edit-action-centered">
                                <button className="button-cancel edit-delete edit-delete-circle"></button>
                                <button className="edit-confirm" onClick={() => {this.trigEdit()}}></button>
                            </div>
                        </div>
                    </div>
                </>
    }
}

export default EditPicture;