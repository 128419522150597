import toast from 'react-hot-toast';

const toastError = (text, id) => {
    toast.error(text, {
        id: id,
        style: {
          padding: '16px',
          color: '#FFF',
          fontWeight: "build",
          borderRadius: "0px",
          backgroundColor: "#de2a3a",
          width: "100%",
          maxWidth: "unset",
          padding: "5px 10px"
        },
        iconTheme: {
          primary: '#de2a3a',
          secondary: '#de2a3a'
        },
      })
    setTimeout(() => {
      toast.dismiss(id);
    }, 4000)
}

const toastSuccess = (text, id) => {
    toast.success(text, {
        id: id,
        duration: 4000,
        style: {
          padding: '16px',
          color: '#FFF',
          fontWeight: "build",
          borderRadius: "0px",
          backgroundColor: "#53b89c",
          width: "100%",
          maxWidth: "unset",
          padding: "5px 10px"
        },
        iconTheme: {
          primary: '#53b89c',
          secondary: '#53b89c'
        },
      })
    setTimeout(() => {
      toast.dismiss(id);
    }, 4000)
}

const toastProgress = (promise) => {
  toast.promise(
    promise(),
     {
       style: {
         border: '1px solid #73F6B7',
         padding: '16px',
         color: '#73F6B7',
         borderRadius: "0px"
       },
       iconTheme: {
         primary: '#73F6B7',
         secondary: '#FFF',
       },
       loading: 'Invio immaggine in corso...',
       success: <b>Immagine inviata</b>,
       error: <b>Errore nell'invio</b>,
     }
  );
}

const removeFile = async (type, position, card, code, callback = (response) => {}) => {
  const axios = require('axios');
  var formData = new FormData();
  formData.append("type", type);
  formData.append("position", position);
  formData.append("card", card);
  formData.append("code", code);


  axios.post('/api/removeFile', formData, {}).then((response) => {
      if(response.status == 200){
          toastSuccess("Immagine rimossa.", "remove-ok")
          callback(200)
          return;
        }
        callback(400)
        toastError("Errore nella rimozione dell'immagine.", "remove-error");
      }).catch((error) => {
        toastError("Errore nella rimozione dell'immagine.", "remove-error");
        callback(400)
  });
}

const saveProfile = (cardId, code, json, callback = () => {}) => {
  const axios = require('axios');
  var formData = new FormData();
  formData.append("card", cardId);
  formData.append("code", code);
  formData.append("json", JSON.stringify(json));


  axios.post('/api/save', formData, {}).then((response) => {
      if(response.status == 200){
        toastSuccess("Profilo salvato.")
        window.location.reload();
        return callback(200);
      }
      return callback(400)
    }).catch((error) => {
      toastError("Errore nel salvataggio.");
      return callback(400)
  });
}

export { toastError, toastSuccess, toastProgress, removeFile, saveProfile }
