import React, { Component } from 'react';

class Addnew extends Component {
    state = {
    }

    constructor(props){
        super(props);
    }

    render() {
        return <button className="button-light margin-button" onClick={this.props.onClick}>Aggiungi +</button>
    }
}

export default Addnew;