import React, { Component } from 'react';
import { BsArchiveFill, BsFillPersonFill } from 'react-icons/bs';
import { MdWork } from 'react-icons/md';
import { ImMobile, ImLocation } from 'react-icons/im';
import { BiWorld, BiReceipt } from 'react-icons/bi';
import { GoNote } from 'react-icons/go';
import { FaPhoneAlt } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { Textfit } from 'react-textfit';
import Addnew from './Addnew';
import Edit from './Edit';

class Map extends Component {
    state = {
        admin: false, // Edit mode
        items: [],
        multi: false
    }
    afterDelete = false;

    constructor(props){
        super(props);

        var card = props.card;
        this.state.card = card;
        this.state.admin = props.admin;

        this.editables = [];

        this.state.items = card.hasOwnProperty(this.props.itemName) ? card[this.props.itemName] : this.state.items;
    }

    componentDidUpdate(prevProps){
        // Cambio stato da visitatore ad admin e viceversa
        if(prevProps.admin != this.props.admin){
            this.setState({admin: this.props.admin});
        }
        if(this.afterDelete){
          this.afterDelete = false;
          this.setState({items: this.state.items});
        }
    }

    checkWidth = () => {

    }

    componentDidMount(){

    }

    getIcon = () => {
        switch(this.props.itemName){
            case "name":
                return <BsFillPersonFill />
            case "companies":
                return <MdWork />
            case "emails":
                return <HiOutlineMail />
            case "mobiles":
                return <ImMobile />
            case "phones":
                return <FaPhoneAlt />
            case "addresses":
                return <>📍</>
            case "websites":
                return <BiWorld />
            case "billing":
                return <BiReceipt />
            default:
                return <GoNote />
        }
    }

    isSet = (id) => {
        if(this.state.admin){
            return false;
        }
        return this.props.hasOwnProperty(id);
    }

    getLink = (txt) => {
        if(this.props.itemName == "emails"){
            return "mailto:" + txt;
        }
        if(this.props.itemName == "websites"){
            if(!txt.indexOf("http")){
                return  txt;
            }
            return "https://" + txt;
        }
        if(this.props.itemName == "mobiles" || this.props.itemName == "phones"){
            return "tel:" + txt;
        }
    }

    getEditable = (id) => {
        return this.editables[id];
    }

    addNew = () => {
        var newstate = this.state.items;
        newstate.push(JSON.parse(JSON.stringify(this.props.default)));
        this.setState({ items: newstate });
        window.card[this.props.itemName] = this.state.items;
    }

    editCallback = (id, newvalue) => {
        this.state.items[id] = newvalue;
        window.card[this.props.itemName] = this.state.items;
        setTimeout(() => {
            this.setState({items: this.state.items});
        }, 1000)
    }

    delete = (id) => {
        this.state.items.splice(id, 1);
        this.afterDelete = true;
        this.setState({items: this.state.items})
        window.card[this.props.itemName] = this.state.items;
    }

    render() {
        this.editables = [];

        if(this.state.items.length == 0 & !this.state.admin){
            return <></>
        }
        var kid = Date.now();

        return <div class="centered">
            <table class="item">
                <tbody>
                    <tr>
                        <td class="item-icon">{ this.getIcon() }</td>

                        <td class="item-name-td">
                            <span class="item-name">{this.props.title}</span>
                        </td>

                        <td class="item-content">
                            {
                                this.state.items.map((e, id) => {
                                    var fields = e;
                                    kid++;

                                    return <div class="item-element" key={kid}>
                                            <div className="item-label-element-container">
                                                <div className={"item-label-element"} ref={(ele) => {if(ele != null)this.editables.push(ele)}}>
                                                    <div className={this.state.admin ? "item-editable" : ""}>
                                                        {
                                                            <span>{ e[0].value }</span>
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    this.state.admin ? <Edit getEditable={this.getEditable} id={id} fields={fields} default={this.props.default} callback={this.editCallback} delete={this.delete} /> : <></>
                                                }
                                            </div>
                                        </div>;
                                })
                            }

                            {
                                (this.state.admin & !this.props.multi & this.state.items.length == 0) ? <Addnew onClick={this.addNew} /> : <></>
                            }
                        </td>
                    </tr>

                    <tr>
                      {
                        this.state.items.map((e, id) => {
                          return <iframe className="map" src={"https://maps.google.com/maps?q=" + e[0].value + "&t=&z=15&ie=UTF8&iwloc=&output=embed"} frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                        })
                      }
                    </tr>
                </tbody>
            </table>
        </div>
    }
}

export default Map;
