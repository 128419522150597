import React, { Component, createRef } from 'react';
import { MdAddAPhoto, MdRemoveCircle } from "react-icons/md";
import UploadPhoto from './UploadPhoto';

class EditCover extends Component {
    state = {
        fields: []
    }

    constructor(props){
        super(props);

        this.state.fields = this.props.fields;

        this.form = createRef();
        this.upload = createRef();
    }

    trigEdit = () => {
        this.upload.current.selectImage();
        return;

        var overlay = document.getElementById("edit-overlay");
        if(overlay.classList.contains("editing")){
            // Chiudo il form
            this.form.current.classList.remove("editing");
            setTimeout(() => {
                overlay.classList.remove("editing");
            }, 800);

            setTimeout(() => {
                this.props.cover.current.classList.remove("cover-focus");
            }, 1800);
        }else{
            // Apro il form
            overlay.classList.add("editing");
            this.form.current.classList.add("editing");

            this.props.cover.current.classList.add("cover-focus");

            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }

    callback = (id, value) => {
        this.props.callback(id, value);
    }

    render() {
        return  <>  <button className="button-light" style={{position: "absolute", bottom: "5px", right: "5px", fontSize: "25px", padding: "7px"}} onClick={this.trigEdit}><div style={{display: "flex", flexDirection: "column", justifyContent: "justify_center"}}><MdAddAPhoto /></div></button>

                    <div className="edit-form" ref={this.form}>
                        <div className="edit-form-div">
                            <div className="item-label" style={{color: "#202020", textAlign: "center", paddingLeft: "0px"}}>Change your cover image</div>
                            <div className="edit-select-image-div">
                                <UploadPhoto code={this.props.code} type="cover" position="1" card={this.props.card} callback={this.callback} ref={this.upload} />
                            </div>
                            <div className="edit-div-action">
                                <button className="edit-confirm" onClick={() => {this.trigEdit()}}></button>
                            </div>
                        </div>
                    </div>
                </>
    }
}

export default EditCover;