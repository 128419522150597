import React, { Component } from 'react';

class Hr extends Component {
    state = {
    }

    constructor(props){
        super(props);
    }

    render() {
        return <div className="centered">
                <div className="item">
                    <div className="centered">
                        <div className="hr"></div>
                    </div>
                </div>  
        </div>
    }
}

export default Hr;