import React, { Component, createRef } from 'react';
import { BsFillTrashFill, BsCheckCircleFill } from 'react-icons/bs';

class EditSlogan extends Component {
    state = {
        text: []
    }

    constructor(props){
        super(props);

        this.state.text = this.props.text;

        this.text = createRef();
        this.form = createRef();
    }

    trigEdit = () => {
        var overlay = document.getElementById("edit-overlay");
        if(overlay.classList.contains("editing")){
            // Chiudo il form
            this.form.current.classList.remove("editing");
            this.props.setText(this.text.current.value);
            window.card.slogan = this.text.current.value;
            setTimeout(() => {
                overlay.classList.remove("editing");
            }, 800);
        }else{
            // Apro il form
            overlay.classList.add("editing");
            this.form.current.classList.add("editing");
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }

    delete = () => {
        window.card.slogan = "";
        this.text.current.value = "";
        this.trigEdit();
    }

    render() {
        return  <>
                    <div style={{textAlign: "right", margin: "auto"}} className="item">
                        <button className="button-text edit-buttont" style={{}} onClick={this.trigEdit}>Modifica</button>
                    </div>

                    <div className="edit-form edit-slogan" ref={this.form}>
                        <div className="edit-form-div">
                            <div className="item-label" style={{color: "#202020", marginBottom: "15px", paddingLeft: "0px", fontSize: "16px"}}>Biografia</div>
                            <div>
                                <textarea rows="4" cols="50" ref={this.text} maxlength="180" className=''>
                                    {this.state.text}
                                </textarea>
                            </div>
                            <div className="item-label" style={{color: "#202020", marginBottom: "15px", paddingLeft: "0px", fontSize: "16px"}}>Max 180 caratteri</div>
                            <div className="edit-div-action">
                                <button className="button-cancel void-button edit-delete" onClick={this.delete} ><BsFillTrashFill /></button>
                                <button className="button-primary edit-confirm void-button confirm" onClick={() => {this.trigEdit()}}><BsCheckCircleFill /></button>
                            </div>
                        </div>
                    </div>
                </>
    }
}

export default EditSlogan;
