import React, { Component } from 'react';

class NotFound extends Component {
    state = {
    }

    constructor(props){
        super(props);
    }

    render() {
        return <div className="centered" style={{flexDirection: "column"}}>
                    <div className="notfound-title">Ooopssss!</div>
                    <div className="notfound-subtitle">Not found!</div>
                    <div className="notfound-subtitle">404</div>
                </div>
    }
}

export default NotFound;