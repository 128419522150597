import React, { Component } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import EditPicture from './EditPicture';

class Picture extends Component {
    state = {
        card: {}, // Card JSON
        admin: false, // Edit mode
        picture: '/static/picture.png'
    }

    constructor(props){
        super(props);

        var card = props.card;
        this.state.card = card;
        this.state.admin = props.admin;

        this.state.picture = (card.hasOwnProperty("picture") & ("" + card.hasOwnProperty("picture")).length > 0) ? '/files/' + card.picture : this.state.picture;
        this.picture = React.createRef();
    }

    componentDidMount() {
        gsap.registerPlugin(ScrollTrigger);
            gsap.fromTo(this.picture.current, {
            y: 0},{
            y: -100,
            ease: 'none',
            scrollTrigger: {
                trigger: this.picture.current,
                start: 'top 200px',
                end: 'bottom top',
                scrub: 1
            }
        });
    }

    newPicture = (id, src) => {
        this.state.picture = '/files/' + src;
        this.setState({picture: this.state.picture});
        window.card.picture = src;
    }

    componentDidUpdate(prevProps){
        // Cambio stato da visitatore ad admin e viceversa
        if(prevProps.admin != this.props.admin){
            this.setState({admin: this.props.admin});
        }
    }

    render() {
        return <div className="centered" style={{height: "0px", marginBottom: "130px"}}>
            <div className="picture" style={{backgroundImage: "url('" + this.state.picture + "')"}} ref={this.picture}>
                {
                    this.state.admin ? <EditPicture picture={this.picture} code={this.props.code} card={this.props.card.id} callback={this.newPicture} /> : <></>
                }
            </div>
        </div>
    }
}

export default Picture;
