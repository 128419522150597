import React, { Component, createRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import { GiPlainCircle } from "react-icons/gi";
import { FaFacebook,FaLinkedin } from "react-icons/fa";
import { AiFillTwitterCircle, AiFillInstagram} from "react-icons/ai";
import Edit from './Edit';
import EditSocial from './EditSocial';
import facebook from'../static/facebook.png';
import twitter from'../static/twitter.png';
import linkedin from'../static/linkedin.png';
import instagram from'../static/instagram.png';
import telegram from'../static/telegram.png';
import messenger from'../static/messenger.png';
import whatsapp from'../static/whatsapp.png';
import twitch from'../static/twitch.png';
import snapchat from'../static/snapchat.png';
import tiktok from'../static/tiktok.png';
import youtube from'../static/youtube.png';
import skype from'../static/skype.png';


class Social extends Component {
    state = {
        card: {}, // Card JSON
        admin: false, // Edit mode
        socials: [],
        slidesPerView: 4
    }

    constructor(props){
        super(props);

        var card = props.card;
        this.state.card = card;
        this.state.admin = props.admin;

        this.state.socials = card.hasOwnProperty("socials") ? card.socials : this.state.socials;
        this.swiper = createRef();
        SwiperCore.use([Pagination]);
    }

    componentDidUpdate(prevProps){
        // Cambio stato da visitatore ad admin e viceversa
        if(prevProps.admin != this.props.admin){
            this.setState({admin: this.props.admin});
        }
    }

    getIcon(icon){
        var icon_cmp = <GiPlainCircle className="social-icon" />

        switch (icon) {
            case "facebook":
                icon_cmp = <>
                                <img className="social-icon" src={facebook}  />
                            </>;
                break;

            case "twitter":
                icon_cmp = <>
                                <img className="social-icon" src={twitter}  />
                            </>;
                break;

            case "linkedin":
                icon_cmp = <>
                                <img className="social-icon" src={linkedin}  />
                            </>;
                break;

            case "instagram":
                icon_cmp = <>
                                <img className="social-icon" src={instagram}  />
                            </>;
                break;

            case "messenger":
                icon_cmp = <>
                                <img className="social-icon" src={messenger}  />
                            </>;
                break;

            case "twitch":
                icon_cmp = <>
                                <img className="social-icon" src={twitch}  />
                            </>;
                break;

            case "whatsapp":
                icon_cmp = <>
                                <img className="social-icon" src={whatsapp}  />
                            </>;
                break;

            case "telegram":
                icon_cmp = <>
                                <img className="social-icon" src={telegram}  />
                            </>;
                break;
            case "skype":
                icon_cmp = <>
                                <img className="social-icon" src={skype}  />
                            </>;
                break;
            case "youtube":
                icon_cmp = <>
                                <img className="social-icon" src={youtube}  />
                            </>;
                break;

            case "snapchat":
                icon_cmp = <>
                                <img className="social-icon" src={snapchat}  />
                            </>;
                break;

            case "tiktok":
                icon_cmp = <>
                                <img className="social-icon" src={tiktok}  />
                            </>;
                break;


            default:
                break;
        }

        return icon_cmp;
    }

    getPrefix = (title) => {
        switch (title.toLowerCase()) {
            case "facebook":
                return "https://facebook.com/"
                break;

            case "instagram":
                return "https://www.instagram.com/"
                break;

            case "tiktok":
                return "https://www.tiktok.com/@"
                break;

            case "twitter":
                return "https://mobile.twitter.com/"
                break;

            case "tumblr":
                return "https://www.tumblr.com/"
                break;

            case "linkedin":
                return "https://www.linkedin.com/in/"
                break;

            case "whatsapp":
                return "https://wa.me/39"
                break;

            case "telegram":
                return "https://t.me/"
                break;

            case "skype":
                return "https://join.skype.com/invite/"
                break;

            case "youtube":
                return "https://www.youtube.com/"
                break;

            case "twitch":
                return "https://www.twitch.tv/"
                break;

            case "snapchat":
                return "https://www.snapchat.com/add/"
                break;

            default:
                break;
        }
        return "";
    }

    isSet(social){
        return (this.state.socials.hasOwnProperty(social) && this.state.socials[social].length > 0);
    }

    getSocials = () => {
        var socials = [];

        //Ordino i social per ordine predefinito
        if(this.isSet("facebook")){
            socials.push("facebook");
        }
        if(this.isSet("instagram")){
            socials.push("instagram");
        }
        if(this.isSet("tiktok")){
            socials.push("tiktok");
        }
        if(this.isSet("twitter")){
            socials.push("twitter");
        }
        if(this.isSet("linkedin")){
            socials.push("linkedin");
        }
        if(this.isSet("whatsapp")){
            socials.push("whatsapp");
        }
        if(this.isSet("telegram")){
            socials.push("telegram");
        }
        if(this.isSet("skype")){
            socials.push("skype");
        }
        if(this.isSet("youtube")){
            socials.push("youtube");
        }
        if(this.isSet("twitch")){
            socials.push("twitch");
        }
        if(this.isSet("snapchat")){
            socials.push("snapchat");
        }

        return socials;
    }

    setSocials = (newSocials) => {
        this.setState({socials: newSocials}, () => {
            this.swiper.current.swiper.update();
        });
        window.card.socials = newSocials;
    }

    render() {
        var socials = this.getSocials();

        return <div className="centered">
            <div className="item">
                {
                    this.state.admin ? <EditSocial fields={this.state.socials} callback={this.setSocials} /> : <></>
                }
                {
                    socials.length > 0 ? <div className="social-margin"></div> : <></>
                }
                <Swiper
                    modules={[Pagination]}
                    slidesPerView={this.state.slidesPerView}
                    centerSlides={true}
                    centerInsufficientSlides={true}
                    pagination={{ el: "#pagination_social" }}
                    ref={this.swiper}
                    >
                        {
                            socials.map((s) => {
                                return <SwiperSlide>
                                            <div className="swiper-content">
                                                <a href={this.getPrefix(s) + this.state.socials[s]}>{ this.getIcon(s) }</a>
                                            </div>
                                        </SwiperSlide>;
                            })
                        }
                </Swiper>
                {
                    socials.length > this.state.slidesPerView ? <div id="pagination_social"></div> : <div id="pagination_social" style={{display: "none"}}></div>
                }
            </div>
        </div>
    }
}

export default Social;
