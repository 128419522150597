import React, { Component, createRef } from 'react';
import { BsFillTrashFill, BsCheckCircleFill } from 'react-icons/bs';
import Input from './Input';

class Edit extends Component {
    state = {
        fields: []
    }

    constructor(props){
        super(props);

        this.state.fields = this.props.fields;

        this.form = createRef();
    }

    componentDidUpdate(prevProps) {
    }

    trigEdit = (cancel = false) => {
        var overlay = document.getElementById("edit-overlay");
        var element = this.props.getEditable([this.props.id]);
        if(element.classList.contains("editing")){
            element.classList.remove("editing");
            this.form.current.classList.remove("editing");
            overlay.classList.remove("editing");
            // Chiusura dell'edit
            setTimeout(() => {
                this.props.callback(this.props.id, this.state.fields);
            }, 200)
            window.trigEdit = null;
        }else{
            element.classList.add("editing");
            this.form.current.classList.add("editing");
            overlay.classList.add("editing");
        }
    }

    delete = () => {
        var overlay = document.getElementById("edit-overlay");
        var element = this.props.getEditable([this.props.id]);
        element.classList.add("deleting");

        this.form.current.classList.remove("editing");
        overlay.classList.remove("editing");

        setTimeout(() => {
            this.props.delete(this.props.id);
        }, 1600)
    }

    callback = (id, value) => {
        this.state.fields[id].value = value;
    }

    render() {
        return  <>  <button class="button-text edit-button" onClick={() => {this.trigEdit()}}>
                        Modifica
                    </button>

                    <div className="edit-form" ref={this.form}>
                        <div className="edit-form-div">
                            {
                                this.state.fields.map((i, id) => {
                                    return <div className="edit-form-field">
                                                <div className="item-label" style={{color: "#202020"}}>{ i.title }</div>
                                                <Input value={i.value} id={id} default={this.props.default[id].value} callback={this.callback} />
                                            </div>
                                })
                            }

                            <div className="edit-div-action">
                                <button className="button-cancel void-button edit-delete" onClick={this.delete} ><BsFillTrashFill /></button>
                                <button className="button-primary edit-confirm void-button confirm" onClick={() => {this.trigEdit()}}><BsCheckCircleFill /></button>
                            </div>
                        </div>
                    </div>
                </>
    }
}

export default Edit;
