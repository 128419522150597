import React, { Component } from 'react';

class Footer extends Component {
    state = {
    }

    constructor(props){
        super(props);
    }

    render() {
        return <div class="centered item footer" style={{backgroundImage: "url('static/footer.jpg')"}}>
                    <div class="footer-table item" >
                            <div class="footer-text">
                                Testo della promo
                                <br></br>
                                Ricevi uno sconto di 10€
                                <br></br>
                                Acquistane una!
                            </div>
                    </div>  
        </div>
    }
}

export default Footer;